/* eslint-disable no-unreachable */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-string-refs */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { generateRatingMeta, filterObject, openUrl } from '../utils/helper';
import toast from 'react-hot-toast';
import {
  postReverseRatingHandler,
  getShortlinkHandler,
  surveyUrlGetter
} from '../service';
import { strings } from '../utils/localizedStrings';
import { withRouter } from 'react-router-dom';
import WizardRating from './rating/WizardRating';
import {
  abfrlCourierRating,
  reverseNPSStatuses
} from '../utils/entity_mapping';
import RatingFirstStep from './rating/RatingFirstStep';
const surveyList = [
  'vanheusenindia',
  'louisphilippe',
  'forever21',
  'thecollective',
  'planetfashion',
  'peterengland',
  'allensolly',
  'simoncarter',
  'aeo',
  'abfrl-test',
  'abfrl-test2',
  'pantaloons',
  'reebok'
];

const shortSurveyLinkList = ['abfrl-test', 'abfrl-test2', 'pantaloons'];

// ['vanheusenindia', 'allensolly', 'peterengland', 'pantaloons', 'louisphilippe'];
// Will start consuming above list once testing is completed.
const oneToFive = 'oneToFive';
class ReverseRating extends Component {
  constructor(props) {
    super(props);
    this.handleRating = this.handleRating.bind(this);
    this.changeRating = this.changeRating.bind(this);
    const { search, hash } = this.props.location;
    const { udfKeys } = props.tracking.theme;
    const searchParams = new URLSearchParams(search + hash);
    // const ratingDefaultvalue = udfKeys.npsType === oneToFive ? 5 : 10;
    const rating =
      props?.user?.subdomain === 'nutrabay'
        ? (searchParams.get('courier_rating') || props.reverseRating.star) ?? 0
        : searchParams.get('courier_rating') || props.reverseRating.star;
    const sliderList =
      udfKeys.npsType === oneToFive
        ? Array.from({ length: 5 }, (_, i) => i + 1)
        : [...Array(11).keys()];

    let percentages = sliderList.map(() => 0);
    let steps = sliderList.length - 1;
    let valPerStep = 100 / steps;
    for (let index = 1; index < sliderList.length; index++) {
      percentages[index] = valPerStep * index;
    }

    this.state = {
      rating: { ...props.reverseRating, star: rating },
      isDirty: false,
      shortLink: '',
      npsType: udfKeys.npsType ?? '',
      redirectSurveyUrl: '',
      slider: {
        percentages,
        list: sliderList,
        value: `${rating}`,
        thumbPosition: `${percentages[sliderList.indexOf(Number(rating))]}%`
      }
    };
  }

  componentDidMount() {
    this.getSurveyLink();
    this.getSurveyURL();
  }

  componentWillReceiveProps(nextProps) {
    const { courierRating } = this.props.page;
    let { reverseRating } = nextProps;
    if (!reverseRating.ratedBefore && !reverseRating.isFetching) {
      this.setState({ show: true });
    } else if (courierRating && !isNaN(courierRating)) {
      const star =
        Number(courierRating) * 2 > 10 ? 10 : Number(courierRating) * 2;
      this.setState({
        slider: {
          ...this.state.slider,
          value: star,
          thumbPosition: `${
            this.state.slider.percentages[
              this.state.slider.list.indexOf(Number(star))
            ]
          }%`
        },
        rating: { ...this.state.rating, star: star }
      });
    }
  }

  getSurveyLink() {
    const { tracking, user } = this.props;
    const { config, customer, referenceNumber, theme } = tracking;
    const udf = theme.meta_data.udf || [];
    const surveyObj = filterObject(udf, 'key', 'surveyId');
    if (
      surveyList.indexOf(user.subdomain) > -1 &&
      surveyObj != undefined &&
      shortSurveyLinkList.indexOf(user.subdomain) > -1
    ) {
      const brandObj = filterObject(udf, 'key', 'brandId');
      const shopNameObj = filterObject(udf, 'key', 'shopname');
      const query = {
        customerid: referenceNumber,
        date: config.orderDate.split(' ')[0],
        email: '',
        mobile: customer.phone,
        mode: 'none',
        website: 1,
        name: customer.name,
        shopname: shopNameObj.value,
        surveyid: surveyObj.value
      };

      return getShortlinkHandler(query).then(res => {
        this.setState({ shortLink: `${res.link}?${brandObj.value}=A` });
        return `${res.link}?${brandObj.value}=A`;
      });
    }
  }

  getSurveyURL() {
    const { tracking } = this.props;
    const { theme } = tracking;
    const udf = theme?.meta_data?.udf || [];
    const { customer, referenceNumber, orderId } = tracking;
    const brandName = filterObject(udf, 'key', 'brandName');
    const uniqueKey = filterObject(udf, 'key', 'unique_key');
    const surveyId = filterObject(udf, 'key', 'survey_id');
    const query = {
      CustomerLastName: customer.name,
      MobileNumber: customer.phone,
      CustomerEmail: customer.email,
      customerID: referenceNumber,
      SurveyMedium: 'Clickpost',
      orderID: orderId,
      ShipmentNo: tracking.waybill,
      BRAND: brandName?.value
    };
    surveyUrlGetter(query, uniqueKey, surveyId)?.then(res =>
      this.setState({ ...this.state, redirectSurveyUrl: res })
    );
  }

  getRatingEmoji() {
    const val = Number(this.state.slider.value);

    if (this.state.npsType === oneToFive) {
      if (val < 3) {
        return '/images/zero-to-two.svg';
      } else if (val === 3) {
        return '/images/six-to-eight.svg';
      } else if (val > 3) {
        return '/images/nine-to-ten.svg';
      }
    }

    if (2 >= val && val >= 0) {
      return '/images/zero-to-two.svg';
    } else if (3 <= val && val <= 6) {
      return '/images/three-to-five.svg';
    } else if (7 <= val && val <= 8) {
      return '/images/six-to-eight.svg';
    } else {
      return '/images/nine-to-ten.svg';
    }
  }
  barColor() {
    const val = Number(this.state.slider.value);

    if (this.state.npsType === oneToFive) {
      if (val === 3) {
        return 'seven-eight';
      } else if (val > 3) {
        return 'above-eight';
      }
    }

    if (7 <= val && val <= 8) {
      return 'seven-eight';
    } else if (val > 8) {
      return 'above-eight';
    }
    return '';
  }

  changeRating(e) {
    let { rating } = this.state;
    if (e.target.id !== undefined && e.target.id !== null) {
      rating.star = Number(e.target.id);
      rating = Object.assign({}, rating, generateRatingMeta(rating.star));
      this.setState({ rating });
      if (this.refs.ratingComment) {
        // Adding ref check here if the element did mount.
        this.refs.ratingComment.placeholder = rating.placeholder;
      }
    }
  }

  // showRatingFeedback = (lang, ratedBefore, subdomain) => {
  //   if (ratedBefore) {
  //     return strings[lang].ratingFeedback;
  //   }
  //   switch (subdomain) {
  //     case 'trell':
  //       return strings[lang].askRatingFeedbackTrellReverse;
  //     case 'arvind':
  //       return strings[lang].askRatingFeedbackArvindReverse;
  //     case 'allensolly':
  //       return strings[lang].askRatingFeedbackAbfrlReverse('Allen Solly');
  //     case 'vanheusenindia':
  //       return strings[lang].askRatingFeedbackAbfrlReverse('Van Heusen India');
  //     case 'louisphilippe':
  //       return strings[lang].askRatingFeedbackAbfrlReverse('Louis philippe');
  //     case 'peterengland':
  //       return strings[lang].askRatingFeedbackAbfrlReverse('Peter England');
  //     case 'planetfashion':
  //       return strings[lang].askRatingFeedbackAbfrlReverse('Planet Fashion');
  //     case 'thecollective':
  //       return strings[lang].askRatingFeedbackAbfrlReverse('The Collective');
  //     case 'forever21':
  //       return strings[lang].askRatingFeedbackAbfrlReverse('Forever 21');
  //     case 'reebok':
  //       return strings[lang].askRatingFeedbackAbfrlReverse('Reebok');
  //     default:
  //       return strings[lang].askReturnExperience;
  //   }
  // };

  getRatingLabel = (lang, ratedBefore, statusCode) => {
    if (ratedBefore) {
      return strings[lang].ratingFeedback;
    }
    if (reverseNPSStatuses.picked.includes(statusCode)) {
      return strings[lang].askReturnExperience;
    }
    if (reverseNPSStatuses.cancelled.includes(statusCode)) {
      return strings[lang].askNonPickedExperience;
    }
    return strings[lang].askReturnExperience;
  };

  handleRating() {
    let { rating } = this.state;
    const { tracking, user, page, location } = this.props;
    const redirectDomain = user.subdomain;
    const { lang } = user;
    const { search, hash } = location;
    const searchParams = new URLSearchParams(search + hash);
    const { customer, orderType, orderId, referenceNumber, theme } = tracking;
    const udf = theme.meta_data.udf || [];
    const surveyObj = filterObject(udf, 'key', 'surveyId');

    if (rating.star === undefined || rating.star === null) {
      return toast.error(strings[lang].ratingErr);
    }
    const query = {
      tracking_id: tracking.trackingId,
      comment: '',
      courier_rating: rating.star,
      security_key: searchParams.get('security_key') || searchParams.get('key'),
      deliveryStatus: tracking.latestStatus.description
    };
    if (this.refs.ratingComment) {
      // Adding ref check here if the element did mount.
      query.comment = this.refs.ratingComment.value;
    }
    if (page.source || page?.utm_source) {
      query.source = page.source || page.utm_source;
    }
    this.setState({ isSubmit: true });
    const abfrlToastObj = filterObject(udf, 'key', 'feedbackToast');
    const qualtricsID = filterObject(udf, 'key', 'qualtricsID');
    const brand = filterObject(udf, 'key', 'Brand');
    const customRating = abfrlCourierRating[rating.star ?? '10'];
    const uniqueKey = filterObject(udf, 'key', 'unique_key');
    const surveyId = filterObject(udf, 'key', 'survey_id');
    if (uniqueKey || surveyId) {
      toast.success(
        abfrlToastObj?.value ??
          'Thank you for the feedback, redirecting you to Survey'
      );
      setTimeout(() => {
        openUrl(
          `${this.state.redirectSurveyUrl}&Q_NPS=${rating.star}`,
          '_self'
        );
      }, 2000);
    } else if (qualtricsID && brand) {
      toast.success(abfrlToastObj.value ?? '');
      setTimeout(() => {
        openUrl(
          `https://abfrlcx.qualtrics.com/jfe/form/${qualtricsID.value}?&Token=${query.security_key}&CustomerLastName=${customer.name}&MobileNumber=${customer.phone}&CustomerEmail=null&orderid=${orderId}&
        deliverystatus=${query.deliveryStatus}&Brand=${brand.value}&ShipmentNo=${referenceNumber}&Q_PopulateResponse=${customRating}`,
          '_self'
        );
      }, 2500);
    } else if (
      surveyList.indexOf(user.subdomain) > -1 &&
      surveyObj != undefined
    ) {
      toast.success(strings[lang].abfrlFeedbackToast);
      setTimeout(() => {
        const brandObj = filterObject(udf, 'key', 'brandId');
        if (shortSurveyLinkList.indexOf(user.subdomain) > -1) {
          if (!this.state.shortLink) {
            this.getSurveyLink().then(function(url) {
              openUrl(
                `${url}${Number(rating.star)}&shipmentID=${tracking.waybill}`,
                '_self'
              );
            });
          } else {
            openUrl(
              `${this.state.shortLink}${rating.star + 1}&shipmentID=${
                tracking.waybill
              }`,
              '_self'
            );
          }
        } else if (user.subdomain === 'vanheusenindia') {
          openUrl(
            `http://survey.${user.subdomain}.com/index.php/${
              surveyObj.value
            }?lang=en&mode=none&name=${customer.name}&mobile=${
              customer.phone
            }&customerid=${customer.phone}&shopname=${
              user.subdomain
            }&surveyid=${
              surveyObj.value
            }&storeaddress=&website=1&orderid=${orderId}&${
              brandObj.value
            }=A${rating.star +
              1}&shipmentno=${referenceNumber}&modeofpayment=${orderType}&shipmentID=${
              tracking.waybill
            }`,
            '_self'
          );
        } else if (
          user.subdomain === 'thecollective' ||
          user.subdomain === 'simoncarter' ||
          user.subdomain === 'planetfashion' ||
          user.subdomain === 'forever21' ||
          user.subdomain === 'aeo'
        ) {
          openUrl(
            `http://survey.${user.subdomain}.in/index.php/${
              surveyObj.value
            }?lang=en&shipmentno=${referenceNumber}&mobile=${
              customer.phone
            }&orderid=${orderId}&name=${
              customer.name
            }&modeofpayment=${orderType}&${brandObj.value}=A${rating.star +
              1}&shipmentID=${tracking.waybill}`,
            '_self'
          );
        } else {
          openUrl(
            `http://survey.${user.subdomain}.com/index.php/${
              surveyObj.value
            }?lang=en&shipmentno=${referenceNumber}&mobile=${
              customer.phone
            }&orderid=${orderId}&name=${
              customer.name
            }&modeofpayment=${orderType}&${brandObj.value}=A${rating.star +
              1}&shipmentID=${tracking.waybill}`,
            '_self'
          );
        }
      }, 2500);
    } else {
      let toastId = null;
      if (!['arvind', 'nutrabay'].includes(redirectDomain)) {
        toastId = toast.loading(strings[lang].actionInProgress);
      }
      postReverseRatingHandler(query).then(response => {
        if (response?.meta?.status === 200) {
          rating.ratedBefore = true;
          rating = Object.assign({}, rating, generateRatingMeta(rating.star));
          this.setState({ isSubmit: false, rating });
          if (!['arvind', 'nutrabay'].includes(redirectDomain)) {
            if (rating.star >= 5) {
              toast.success(strings[lang].feedbackToastr1, { id: toastId });
            } else {
              toast.success(strings[lang].feedbackToastr2, { id: toastId });
            }
            // setTimeout(() => this.setState({ ...this.state, show: false }), 6000);
          } else if (redirectDomain === 'arvind') {
            // Added for arvind
            openUrl('https://survey.zohopublic.in/zs/JODw7A', '_blank');
          } else if (redirectDomain === 'nutrabay') {
            openUrl(
              'https://nutrabay.typeform.com/to/TME5l1?utm_source=web&utm_medium=menu&utm_campaign=Feedback&utm_content=Survey_CTA',
              '_blank'
            );
          }
        } else {
          toast.error(strings[lang].apiErr);
          this.setState({ isSubmit: false });
        }
      });
    }
  }

  preventClick = e => {
    e.stopPropagation;
    e.nativeEvent.stopImmediatePropagation();
    return;
  };

  conditionalRating = evt => {
    evt.persist();
    const { tracking, user } = this.props;
    const { customer, orderType, orderId, referenceNumber, theme } = tracking;
    const udf = theme.meta_data.udf || [];
    const surveyObj = filterObject(udf, 'key', 'surveyId');
    if (surveyList.indexOf(user.subdomain) > -1 && surveyObj != undefined) {
      const brandObj = filterObject(udf, 'key', 'brandId');
      if (shortSurveyLinkList.indexOf(user.subdomain) > -1) {
        if (!this.state.shortLink) {
          this.getSurveyLink().then(function(url) {
            openUrl(`${url}${parseInt(evt.target.id) + 1}`, '_self');
          });
        } else {
          openUrl(
            `${this.state.shortLink}${parseInt(evt.target.id) + 1}`,
            '_self'
          );
        }
      } else if (
        user.subdomain === 'vanheusenindia' ||
        user.subdomain === 'louisphilippe'
      ) {
        openUrl(
          `http://survey.${user.subdomain}.com/index.php/${
            surveyObj.value
          }?lang=en&mode=none&name=${customer.name}&mobile=${
            customer.phone
          }&customerid=${customer.phone}&shopname=${user.subdomain}&surveyid=${
            surveyObj.value
          }&storeaddress=&website=1&orderid=${orderId}&${
            brandObj.value
          }=A${parseInt(evt.target.id) +
            1}&shipmentno=${referenceNumber}&modeofpayment=${orderType}`,
          '_self'
        );
      } else if (
        user.subdomain === 'thecollective' ||
        user.subdomain === 'simoncarter' ||
        user.subdomain === 'planetfashion' ||
        user.subdomain === 'forever21' ||
        user.subdomain === 'aeo'
      ) {
        openUrl(
          `http://survey.${user.subdomain}.in/index.php/${
            surveyObj.value
          }?lang=en&shipmentno=${referenceNumber}&mobile=${
            customer.phone
          }&orderid=${orderId}&name=${
            customer.name
          }&modeofpayment=${orderType}&${brandObj.value}=A${parseInt(
            evt.target.id
          ) + 1}`,
          '_self'
        );
      } else {
        openUrl(
          `http://survey.${user.subdomain}.com/index.php/${
            surveyObj.value
          }?lang=en&shipmentno=${referenceNumber}&mobile=${
            customer.phone
          }&orderid=${orderId}&name=${
            customer.name
          }&modeofpayment=${orderType}&${brandObj.value}=A${parseInt(
            evt.target.id
          ) + 1}`,
          '_self'
        );
      }
    } else if (user.subdomain === 'arvind') {
      // For arvind we are hiding submit button and on rating number click and sending post request to the backend.
      this.changeRating(evt);
      this.handleRating();
    } else {
      return this.changeRating(evt);
    }
  };

  onRatingChange = value => {
    const ratingMetadata = generateRatingMeta(
      Number(value),
      this.state.npsType === oneToFive,
      this.props.user.lang
    );
    this.setState({
      rating: {
        star: Number(value),
        ...ratingMetadata
      },
      slider: {
        ...this.state.slider,
        value: value,
        thumbPosition: `${
          this.state.slider.percentages[
            this.state.slider.list.indexOf(Number(value))
          ]
        }%`
      },
      isDirty: true
    });
    if (this.refs.ratingComment) {
      // Adding ref check here if the element did mount.
      this.refs.ratingComment.placeholder = ratingMetadata.placeholder;
    }
  };

  render() {
    const { tracking, user } = this.props;
    const { rating } = this.state;
    const { lang } = user;
    const feedbackMessage = this.getRatingLabel(
      lang,
      rating.ratedBefore,
      tracking?.scans?.[0]?.code
    );
    if (!tracking.config.isReverseRating || !tracking?.config?.isRVP) {
      return null;
    }
    // let thumbStyle = { left: this.state.slider.thumbPosition };
    // if (this.state.slider.list.at(-1) === Number(this.state.slider.value)) {
    //   thumbStyle = { right: '-22px' };
    // }

    return tracking.isFetching ? null : ['nutrabay'].indexOf(user.subdomain) >
      -1 ? (
      <WizardRating
        lang={lang}
        feedbackMessage={feedbackMessage}
        trackingId={tracking.trackingId}
        priorRating={rating}
      />
    ) : (
      <div className={`card ${rating.ratedBefore ? 'disabled' : ''}`}>
        <p className="li-ht-24 text-left">
          <strong>{feedbackMessage}</strong>
        </p>
        <div className={`row col-gutter-30`}>
          <div className="col-md-24 mrg-top-10 xs-mrg-top-0 rating-container">
            <RatingFirstStep
              state={{ rating: rating.star }}
              lang={lang}
              onButtonClick={this.onRatingChange}
              npsType={this.state.npsType}
            />
            {/* <div className="slider-container">
              <div className="slider">
                <div className="slider-text">
                  <div>
                    <p className="text">
                      {surveyList.indexOf(user.subdomain) > -1
                        ? strings[lang].veryPoorAbfrl
                        : strings[lang].veryPoor}
                    </p>
                    <p className="text">
                      {surveyList.indexOf(user.subdomain) > -1
                        ? strings[lang].excellentAbfrl
                        : strings[lang].excellent}
                    </p>
                  </div>
                  <div>
                    {this.state.slider.list.map(ratings => (
                      <p className="rating-no" key={ratings}>
                        {ratings}
                      </p>
                    ))}
                  </div>
                  <div>
                    <div
                      className="d-flex justify-center"
                      style={{ width: '100%' }}
                    >
                      <div
                        className="ticks-wrapper d-flex justify-between"
                        style={{
                          width: `${(100 /
                            (this.state.slider.list.length - 1)) *
                            (this.state.slider.list.length - 3)}%`,
                          left: `${100 / (this.state.slider.list.length - 1)}%`
                        }}
                      >
                        {[
                          ...Array(this.state.slider.list.length - 2).keys()
                        ].map(ticks => (
                          <div className="ticks" key={ticks} />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="slider-wrapper">
                  <input
                    type="range"
                    min={this.state.slider.list[0]}
                    max={this.state.slider.list.at(-1)}
                    value={this.state.slider.value}
                    onInput={e => this.onSliderChange(e)}
                    className="slider-native"
                  ></input>
                  <div className="thumb" style={thumbStyle}>
                    <img src={this.getRatingEmoji()} alt="" />
                  </div>
                  <div
                    className={`progress-bar ${this.barColor()}`}
                    style={{ width: this.state.slider.thumbPosition }}
                  />
                </div>
              </div>
            </div> */}
          </div>
          {user.subdomain === 'arvind' ||
          surveyList.indexOf(user.subdomain) > -1 ? null : (
            <div
              className={`col-md-24 mrg-top-10 xs-mrg-top-0 mrg-btm-10 ${
                rating.star !== undefined && rating.star !== null
                  ? 'show'
                  : 'hide'
              }`}
            >
              <textarea
                ref="ratingComment"
                placeholder={strings[lang].inputFeedback}
                className="input-textarea"
                defaultValue={this.props.reverseRating.comment || ''}
              />
            </div>
          )}
          <div className="col-md-24 mrg-top-10 md-text-left">
            <button
              className={`button rating 
                ${
                  rating.star !== undefined && rating.star !== null
                    ? ''
                    : 'opacity-5'
                } ${!this.state.isDirty && 'opacity-5'}
                ${user.subdomain === 'damensch' ? 'damensch' : ''}`}
              disabled={!this.state.isDirty || rating.ratedBefore}
              onClick={
                rating.ratedBefore ||
                !(rating.star !== undefined && rating.star !== null)
                  ? null
                  : this.handleRating
              }
            >
              {strings[lang].submit}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

ReverseRating.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  reverseRating: PropTypes.object,
  page: PropTypes.object,
  location: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  reverseRating: state.reverseRating,
  user: state.user,
  page: state.page
});

export default withRouter(connect(mapStateToProps, null)(ReverseRating));
