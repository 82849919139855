/* eslint-disable react/no-string-refs */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
// import 'url-search-params-polyfill';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import toast from 'react-hot-toast';
import Slider from 'react-slick';
import moment from 'moment';
import dompurify from 'dompurify';
import RatingCard from '../../rating';
import AppPopUp from '../../appPopUp';
import { PageSpinner } from '../../../staticComponents/spinner';
import {
  pageHandler,
  trackingHandler,
  ratingHandler,
  opsFeedHandler,
  customerFeedHandler,
  updateUserHandler,
  eddHandler,
  postConcernHandler,
  nprOpsFeedHandler,
  nprCustomerFeedHandler,
  reverseRatingHandler
  // pageRedirectionHandler
} from '../../../service';
import {
  order as orderData,
  issueType,
  calendarDates,
  isUrl,
  reverseOrder,
  reverseNPSStatuses
} from '../../../utils/entity_mapping';
import {
  mappingDateByStatuses,
  datetimeFormatter,
  enableFormSubmit,
  clickEventDescription,
  ndrButtonText,
  ga4ClickEvent
} from '../../../utils/helper';
import { Object } from 'core-js';
import actionMessages from '../../../utils/messages';
import DatePicker from 'react-datepicker';
import NotFoundCard from '../../NotFoundCard';
import Issue from './Issue';
import Payment from '../../Payment';
import ReverseRating from '../../reverseRating';

class Tracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      action: { show: false, active: 0, notification: false },
      width: 0,
      characters: 0,
      appLink: '',
      showAppLink: false,
      subdomain: '',
      shareOn: false,
      counter: 0,
      dateMeta: [],
      preferred_date: null,
      isUserDate: false,
      ndrScreen: 0,
      customerFeedId: 0,
      currentIssueId: 0,
      isAltNumber: null,
      ndrForm: {
        isValid: false,
        is_rejected: null,
        address: '',
        drop_pincode: null,
        landmark: null,
        preferred_date: null,
        comment: '',
        isSubmitting: false,
        capturePincode: false // Not using anymore
      },
      invalidShipment: false,
      message: '',
      subMessage: '',
      isReverseIssue: false,
      customerFeedback: {},
      security_key: ''
    };
  }

  fetchTracking = () => {
    const { page, user } = this.props;
    const { search, pathname, hash } = this.props.location;
    const searchParams = new URLSearchParams(search + hash);
    const security_key =
      searchParams.get('security_key') || searchParams.get('key');
    this.setState({ security_key });
    const query = {
      cpId: searchParams.get('cp_id'),
      waybill: searchParams.get('waybill'),
      orderId: searchParams.get('order_id'),
      security_key: security_key,
      courierRating: searchParams.get('courier_rating'),
      app_only: searchParams.get('app_only'),
      altUrl: searchParams.get('altUrl')
    };

    if (query.orderId) {
      query.orderId = encodeURIComponent(query.orderId);
    }

    const url = pathname + search;
    this.props.setPageHandler(
      Object.assign(
        query,
        { url },
        {
          isSearch: query.cpId || query.waybill || query.orderId ? false : true
        }
      )
    );
    const { isNdrFetched, theme } = this.props.tracking;
    const { routePrefix } = user;
    if ((query.cpId || query.waybill || query.orderId) && !isNdrFetched) {
      this.props.fetchTrackingHandler(query).then(res => {
        if (res.meta && res.meta.success) {
          const { tracking } = this.props;
          if (!tracking.valid && query.altUrl) {
            // pageRedirectionHandler({
            //   url: query.altUrl,
            //   waybill: query.waybill,
            //   enterpriseId: tracking.theme.enterprise_user_id
            // });
            window.location.href = isUrl(query.altUrl)
              ? query.altUrl
              : `https://${query.altUrl}`;
          }
          if ('shipments' in res.result) {
            return this.props.history.push(
              `/${routePrefix ? `${routePrefix}` : ''}?${
                query.app_only ? `&app_only=${query.app_only}` : ''
              }`
            );
          }
          // edd configuration
          if (theme.meta_data && !theme.meta_data.remove_edd) {
            this.props.fetchEddHandler(query);
          }
          if (user.env !== 'development') {
            const { location } = this.props.history;
            ReactGA4.send({
              hitType: 'pageview',
              page: location.pathname + location.search,
              enterprise: user.enterprise,
              uber_status: tracking.latestStatus.code,
              waybill: tracking.waybill,
              cp_name: tracking.courier.actName,
              tracking_source: page.source || '',
              cp_id: tracking.courier.id,
              enterprise_id: tracking.theme.enterprise_user_id
            });

            ReactGA.set({ dimension1: user.enterprise });
            ReactGA.set({ dimension2: tracking.latestStatus.code });
            ReactGA.set({ dimension4: tracking.waybill });
            ReactGA.set({ dimension5: tracking.courier.actName });
            ReactGA.set({ dimension7: page.source || '' });
            ReactGA.set({ dimension8: tracking.courier.id });
            ReactGA.set({ dimension9: theme.enterprise_user_id });
            ReactGA.pageview(location.pathname + location.search);
          }
          if (res.meta && res.meta.success && res.result.config.is_rvp) {
            if (
              reverseOrder.pickupFailed.indexOf(
                res.result[query.waybill].latest_status.clickpost_status_code
              ) > -1 &&
              res.result.config.is_pickup_form_visible
            ) {
              this.props
                .fetchNprOpsFeedHandler(tracking.trackingId, query.security_key)
                .then(res => {
                  if (
                    res.meta &&
                    res.meta.success &&
                    !tracking.config.invalidSecurityCheck
                  ) {
                    this.props
                      .fetchNprCustomerFeedHandler(
                        tracking.trackingId,
                        query.security_key
                      )
                      .then(res => {
                        if (res.meta && res.meta.success) {
                          this.setState({
                            isReverseIssue: true,
                            customerFeedback: res.result.feed ?? {}
                          });
                        }
                      });
                  }
                });
            }
            if (
              tracking.config.isReverseRating &&
              reverseNPSStatuses.all.indexOf(tracking.latestStatus.code) > -1
            ) {
              this.props.fetchReverseRatingHandler(tracking.trackingId);
            }
          }
          if (
            tracking.config.isRating &&
            tracking.latestStatus.code === 8 &&
            !res.result.config.is_rvp
          ) {
            this.props.fetchRatingHandler(tracking.trackingId);
          }
          // If current status is failed, it'll popup concern/issue form.
          if (
            (tracking.order.isFailed || tracking.order.everFailed) &&
            tracking.config.isFailedDelivery &&
            !tracking.config.invalidSecurityCheck
          ) {
            this.props
              .fetchOpsFeedHandler(tracking.trackingId, tracking.config.user)
              .then(resp => {
                if (resp.meta && resp.meta.success) {
                  this.props
                    .fetchCustomerFeedHandler(
                      tracking,
                      tracking.customer,
                      security_key
                    )
                    .then(res => {
                      if (
                        res.meta &&
                        res.meta.success &&
                        this.props.customerFeed
                      ) {
                        // this.props.history.push(
                        //   '/issue' + this.props.location.search
                        // );
                        let ndrScreen = 1;
                        let currentIssueId = null;
                        const {
                          ndr_reattempt_day_limit
                        } = this.props.tracking.theme.meta_data;
                        // CalendarDates skipping the first day of the calendar, adding 1 more day to the date iterator explicitly
                        const dateMeta = calendarDates({
                          itr: ndr_reattempt_day_limit + 1
                        });
                        this.setState({ dateMeta });
                        // uncomment when ofd clear last ndr filled info
                        if (
                          this.props.customerFeed.issue_id !== null &&
                          res.result.new_ndr === false
                        ) {
                          ndrScreen = 4;
                          currentIssueId = res.result.feed.issue_id;
                        } else if (res.result.new_ndr) {
                          ndrScreen = 1;
                          currentIssueId =
                            resp.result.feed.issue_config.issue_id;
                        }
                        let { ndrForm } = this.state;
                        ndrForm.address = res.result.feed.address || '';
                        ndrForm.drop_pincode =
                          res.result.feed.drop_pincode || '';
                        ndrForm.landmark = res.result.feed.landmark || '';
                        ndrForm.preferred_date = moment(
                          res.result.feed.preferred_date,
                          'YYYY-MM-DD'
                        );
                        ndrForm.phone_number = res.result.feed.phone_number;
                        ndrForm.is_rejected = res.result.feed.is_rejected;
                        // if (
                        //   theme.meta_data &&
                        //   theme.meta_data.capture_ndr_pincode
                        // ) {
                        //   ndrForm.capturePincode = true;
                        // } else {
                        //   ndrForm.capturePincode = false;
                        // }
                        this.setState({
                          ndrScreen,
                          currentIssueId,
                          ndrForm // uncomment when ofd clear last ndr filled info
                        });
                      }
                    });
                }
              });
          }
        } else {
          if (res.meta && res.meta.isHome) {
            // this.props.history.push(`/${routePrefix ? `${routePrefix}` : ''}?${query.app_only ? `&app_only=${query.app_only}` : ''}`);
            this.setState({
              ...this.state,
              invalidShipment: true,
              message: res.meta.message
            });
          } else {
            const { counter } = this.state;
            this.setState({ counter: counter + 1 });
            if (res.meta && res.meta.status >= 400 && res.meta.message) {
              // this.props.history.push(`/${routePrefix ? `${routePrefix}` : ''}?${query.app_only ? `&app_only=${query.app_only}` : ''}`);
              this.setState({
                ...this.state,
                invalidShipment: true,
                message: res.meta.message
              });
              if (query.altUrl) {
                // pageRedirectionHandler({
                //   url: query.altUrl,
                //   waybill: query.waybill,
                //   enterpriseId: this.props.tracking.theme.enterprise_user_id
                // });
                window.location.href = isUrl(query.altUrl)
                  ? query.altUrl
                  : `https://${query.altUrl}`;
              }
            } else {
              this.setState({
                ...this.state,
                invalidShipment: true,
                message: res.meta.message
              });
              if (this.state.counter < 2) {
                this.fetchTracking();
              }
            }
          }
        }
      });
    }
  };

  componentDidUpdate(prevProps) {
    const nextSearch = this.context.router.history.location.search;
    if (prevProps.location.search !== nextSearch) {
      // if(this.refs.searchInput){
      //   this.refs.searchInput.value = '';
      // }
      // this.setState({searchInput: '', appLink: ''});
      this.fetchTracking();
    }
  }

  closeShare = () => {
    let { user } = this.props;
    user = Object.assign(user, { shareOn: false });
    this.props.updateUserHandler(user);
  };

  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions() {
    const { user } = this.props;
    let characters = 0;
    const width = window.innerWidth;
    if (width < 321) {
      characters = 72;
    } else if (width < 376) {
      characters = 85;
    } else if (width < 415) {
      characters = 110;
    } else if (width < 769) {
      characters = 120;
    } else if (width < 1025) {
      characters = 120;
    } else if (width > 1024) {
      characters = 180;
    }
    this.setState({ width: width, characters });
    this.props.updateUserHandler(
      Object.assign(user, { device: width > 768 ? 'desktop' : 'mobile' })
    );
  }

  scrolling() {
    let { showAppLink } = this.state;
    const { appLink, hideLink } = this.props.user;
    if (window.pageYOffset > 50) {
      if (!showAppLink && appLink && !hideLink) {
        this.setState({ showAppLink: true });
      }
    }
  }

  /**
   * Add event listener
   */
  componentDidMount() {
    this.fetchTracking();
    // window.addEventListener('resize', this.updateDimensions.bind(this));
    window.addEventListener('scroll', this.scrolling.bind(this));
    // Detecting device information
    this.updateDimensions();
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    // window.removeEventListener('resize', this.updateDimensions.bind(this));
    window.removeEventListener('scroll', this.scrolling.bind(this));
  }

  // componentDidUpdate(prevProps) {
  //   const nextSearch = this.context.router.history.location.search;
  //   if (prevProps.location.search !== nextSearch) {
  //     if(this.refs.searchInput){
  //       this.refs.searchInput.value = '';
  //     }
  //     this.setState({searchInput: '', appLink: ''});
  //   }
  // }

  // toggleTopNotification(){
  //   const { action } = this.state;
  //   action.notification = !action.notification;
  //   this.setState({ action });
  // }

  gaEvents(e, eventName, label, action) {
    if (action) {
      ga4ClickEvent({ category: eventName, action, label, props: this.props });
      ReactGA.event({
        category: eventName,
        label: label,
        action: action
      });
    }
  }

  onCalendarChange = date => {
    const { opsFeed, customerFeed } = this.props;
    const { capture } = customerFeed;
    let { ndrForm, customerFeedId, isAltNumber, currentIssueId } = this.state;
    ndrForm.preferred_date = '';
    if ((customerFeedId === 2 || opsFeed.issue_id === 2) && date) {
      ndrForm.isValid = true;
    }
    ndrForm.isValid = enableFormSubmit(
      Object.assign(ndrForm, {
        preferred_date: date
          ? moment(new Date(date)).format('YYYY-MM-DD')
          : null,
        currentIssueId,
        isAltNumber,
        capture
      })
    );
    this.setState({
      ndrForm,
      preferred_date: date ? date : null,
      isUserDate: true
    });
  };

  ndrScreenChange = (e, screen) => {
    const { opsFeed } = this.props;
    let { customerFeedId, currentIssueId } = this.state;
    if (screen !== 3) {
      customerFeedId = 0;
    }
    if (screen === 1) {
      currentIssueId = opsFeed.issue_id;
    } else if (screen === 3) {
      currentIssueId = customerFeedId;
    } else {
      customerFeedId = 0;
      currentIssueId = 0;
    }
    this.setState({
      ndrScreen: screen,
      customerFeedId,
      currentIssueId,
      preferred_date: null,
      isAltNumber: null,
      ndrForm: {
        isValid: false,
        is_rejected: null,
        address: '',
        drop_pincode: null,
        landmark: null,
        preferred_date: null,
        comment: '',
        isSubmitting: false,
        capturePincode: false // Not using anymore
      }
    });
  };

  handleCustomerReason = e => {
    const customerFeedId = Number(e.target.id);
    this.setState({ customerFeedId });
  };

  handleReject = e => {
    const { capture } = this.props.customerFeed;
    const { ndrForm, currentIssueId } = this.state;
    const rejectType = Number(e.target.id);
    if (rejectType === 1) {
      ndrForm.is_rejected = false;
    } else {
      ndrForm.is_rejected = true;
    }
    ndrForm.preferred_date = null;
    ndrForm.isValid = enableFormSubmit(
      Object.assign(ndrForm, { currentIssueId, capture })
    );
    this.setState({ ndrForm, preferred_date: null });
  };

  handleAltNumber = e => {
    const { tracking, customerFeed } = this.props;
    const { capture } = customerFeed;
    let { ndrForm, isAltNumber, currentIssueId } = this.state;
    const id = Number(e.target.id);
    if (id === 2) {
      isAltNumber = true;
      ndrForm.phone_number = '';
    } else if (id === 1) {
      isAltNumber = false;
      ndrForm.phone_number = tracking.customer.phone;
    }
    ndrForm.isValid = enableFormSubmit(
      Object.assign(ndrForm, { isAltNumber, currentIssueId, capture })
    );
    this.setState({ ndrForm, isAltNumber });
  };

  dateToggle = e => {
    const { ndrForm } = this.state;
    ndrForm.preferred_date = e.target.id;
    ndrForm.isValid = true;
    this.setState({ ndrForm, preferred_date: null });
  };

  changeValue = e => {
    const { ndrForm, preferred_date, currentIssueId } = this.state;
    const { capture } = this.props.customerFeed;
    ndrForm[e.target.name] = e.target.value;
    ndrForm.isValid = enableFormSubmit(
      Object.assign(ndrForm, { preferred_date, currentIssueId, capture })
    );
    this.setState({ ndrForm });
  };

  getIssueHeading = no => {
    switch (no) {
      case 4:
        return (
          <Fragment>
            <p>
              In some cases, courier partners call from landline which might
              feel as spam to you. We will ask the courier partner to reattempt
              the delivery.
            </p>
            <p className="mrg-top-10 mrg-btm-20">
              Do you want the courier partner to call you on the same number as
              given during order placement?
            </p>
          </Fragment>
        );
      case 1:
        return (
          <p className="mrg-btm-20">
            The courier partner informs us that they tried to reach your address
            but could not find your address. Can you provide us more details
            about the address?
          </p>
        );
      case 2:
        return (
          <Fragment>
            <p>
              Our courier partners attempt 3 times for a delivery. In the case,
              where the package could not be delivered the first time, we
              reattempt it again the next day excluding Sundays and public
              holidays.
            </p>
            <p className="mrg-top-10">
              Kindly make sure that someone is available at your delivery
              address to receive the package.
            </p>
            <p className="mrg-top-10 mrg-btm-20">
              Do you want to get the delivery attempt on specific days?
            </p>
          </Fragment>
        );
      case 3:
        return (
          <p className="mrg-btm-20">
            Please share any additional details that could help us to deliver
            the order.
          </p>
        );
      case 6:
        return (
          <p className="mrg-btm-20">
            Please confirm if you want us to make another delivery attempt for
            your shipment:
          </p>
        );
      default:
    }
  };

  renderNdrSuccessView = no => {
    const { ndrForm } = this.state;
    switch (no) {
      case 1:
        return (
          <Fragment>
            <p className="mrg-btm-5 expected-date-label">
              Expected delivery by
            </p>
            <p className="text-theme expected-date mrg-btm-15">
              Delivery scheduled for{' '}
              {moment(ndrForm.preferred_date).format('DD-MM-YYYY')}
            </p>
            <p className="mrg-btm-10">
              Our courier partner will attempt to deliver to the following
              address
            </p>
            <p className="mrg-btm-20">
              <strong>
                {ndrForm.address}
                <br />
                {ndrForm.drop_pincode}
                <br />
                {ndrForm.landmark}
              </strong>
            </p>
            <p className="mrg-btm-20 expected-date-label">
              We will notify you as soon as this order is out for delivery.{' '}
            </p>
          </Fragment>
        );
      case 2:
      case 3:
        return (
          <Fragment>
            <p className="mrg-btm-5 expected-date-label">
              Expected delivery by
            </p>
            <p className="text-theme expected-date mrg-btm-15">
              Delivery scheduled for{' '}
              {moment(ndrForm.preferred_date).format('DD-MM-YYYY')}
            </p>
            <p className="mrg-btm-20 expected-date-label">
              We will notify you as soon as this order is out for delivery.{' '}
            </p>
          </Fragment>
        );
      case 4:
        return (
          <Fragment>
            <p className="mrg-btm-5 expected-date-label">
              Expected delivery by
            </p>
            <p className="text-theme expected-date mrg-btm-15">
              Delivery scheduled for{' '}
              {moment(ndrForm.preferred_date).format('DD-MM-YYYY')}
            </p>
            {ndrForm.phone_number ? (
              <p className="mrg-btm-20">
                Our courier partner will attempt to contact you at +91{' '}
                <strong>{ndrForm.phone_number}</strong>
              </p>
            ) : null}
            <p className="mrg-btm-20 expected-date-label">
              We will notify you as soon as this order is out for delivery.{' '}
            </p>
          </Fragment>
        );
      case 6:
        return (
          <Fragment>
            {ndrForm.is_rejected ? (
              <Fragment>
                <p className="text-theme expected-date mrg-btm-15">
                  Delivery cancelled
                </p>
                <p className="mrg-btm-20">
                  For further details of this order please visit My Order page.
                </p>
              </Fragment>
            ) : (
              <Fragment>
                {ndrForm.preferred_date ? (
                  <Fragment>
                    <p className="mrg-btm-5 expected-date-label">
                      Expected delivery by
                    </p>
                    <p className="text-theme expected-date mrg-btm-15">
                      Delivery scheduled for{' '}
                      {moment(ndrForm.preferred_date).format('DD-MM-YYYY')}
                    </p>
                  </Fragment>
                ) : null}
                <p className="mrg-btm-20 expected-date-label">
                  We will notify you as soon as this order is out for delivery.{' '}
                </p>
              </Fragment>
            )}
          </Fragment>
        );
      default:
        break;
    }
  };

  renderNdrView = no => {
    const { opsFeed } = this.props;
    const { ndrScreen, ndrForm, customerFeedId, currentIssueId } = this.state;
    const sanitizer = dompurify.sanitize;
    switch (no) {
      case 1:
        return (
          <Fragment>
            <p className="expected-date mrg-btm-20">
              Could not deliver your order
            </p>
            <p
              className="mrg-btm-20"
              dangerouslySetInnerHTML={{
                __html: sanitizer(opsFeed.raw_issue_desc)
              }}
            />
            {this.getFields(opsFeed.issue_id)}
            <div className="request-block pd-15 col-gutter-20 xs-col-gutter-10 mrg-btm-10">
              <div className="pd-top-15 pd-btm-5">
                <button
                  className={`nyk-btn text-uppercase ${
                    ndrForm.isValid
                      ? ndrForm.isSubmitting
                        ? 'disabled'
                        : ''
                      : 'disabled'
                  }`}
                  onClick={this.handleNdrSubmit}
                >
                  {ndrScreen === 2
                    ? 'NEXT'
                    : ndrForm.isSubmitting
                    ? 'SUBMITTING...'
                    : ndrButtonText(opsFeed.issue_id)}
                </button>
              </div>
              <p className="pd-top-20 pd-btm-20 nyk-issue-btn">
                <span
                  className="cur-pointer"
                  onClick={e => this.ndrScreenChange(e, 2)}
                >
                  ARE YOU FACING ANY OTHER ISSUE?
                </span>
              </p>
            </div>
          </Fragment>
        );

      case 2:
        return (
          <Fragment>
            <form className="slick-form">
              <p className="expected-date">Please tell us what happened?</p>
              <p className="char-counter text-left mrg-btm-15">
                <em>
                  Press <span className="highlight">Next</span> button at bottom
                  after selection.
                </em>
              </p>
              {issueType.map((issue, i) => (
                <div key={i} className="mrg-btm-15">
                  <input
                    id={issue.id}
                    name="ndr-checkbox"
                    className="ndr-checkbox"
                    type="radio"
                  />
                  <label
                    id={issue.id}
                    className="pre-text"
                    htmlFor={issue.id}
                    onClick={this.handleCustomerReason}
                  >
                    {issue.title}
                  </label>
                </div>
              ))}
            </form>

            <div className="request-block pd-15 col-gutter-20 xs-col-gutter-10 mrg-btm-10">
              <div className="pd-top-15 pd-btm-5">
                <button
                  className={`nyk-btn ${customerFeedId > 0 ? '' : 'disabled'}`}
                  onClick={e => this.ndrScreenChange(e, 3)}
                >
                  NEXT
                </button>
              </div>
              <p className="pd-top-20 pd-btm-20 nyk-issue-btn">
                <span
                  className="cur-pointer"
                  onClick={e => this.ndrScreenChange(e, 1)}
                >
                  BACK
                </span>
              </p>
            </div>
          </Fragment>
        );

      case 3:
        return (
          <Fragment>
            <p className="expected-date mrg-btm-20">
              Could not deliver your order
            </p>
            {this.getIssueHeading(customerFeedId)}
            {this.getFields(customerFeedId)}
            <div className="request-block pd-15 col-gutter-20 xs-col-gutter-10 mrg-btm-10">
              <div className="pd-top-15 pd-btm-5">
                <button
                  className={`nyk-btn text-uppercase ${
                    ndrForm.isValid
                      ? ndrForm.isSubmitting
                        ? 'disabled'
                        : ''
                      : 'disabled'
                  }`}
                  onClick={this.handleNdrSubmit}
                >
                  {ndrForm.isSubmitting
                    ? 'SUBMITTING...'
                    : ndrButtonText(customerFeedId)}
                </button>
              </div>
              <p className="pd-top-20 pd-btm-20 nyk-issue-btn">
                <span
                  className="cur-pointer"
                  onClick={e => this.ndrScreenChange(e, 2)}
                >
                  BACK
                </span>
              </p>
            </div>
          </Fragment>
        );

      case 4:
        return (
          <Fragment>
            {this.renderNdrSuccessView(currentIssueId)}
            <div className="request-block pd-15 col-gutter-20 xs-col-gutter-10 mrg-btm-10">
              <p className="pd-top-20 pd-btm-20 nyk-issue-btn">
                <span
                  className="cur-pointer"
                  onClick={e => this.ndrScreenChange(e, 2)}
                >
                  ARE YOU FACING ANY OTHER ISSUE?
                </span>
              </p>
            </div>
          </Fragment>
        );

      default:
        break;
    }
  };

  getFields(issueId) {
    const { ndrForm, isAltNumber } = this.state;
    const { customerFeed } = this.props;
    switch (issueId) {
      case 1:
        return (
          <Fragment>
            <p className="heading-4 mrg-btm-15">
              Enter correct delivery details
            </p>
            <form className="slick-form">
              <div className="mrg-btm-20">
                <textarea
                  name="address"
                  placeholder="Complete Address"
                  maxLength="255"
                  onChange={e => this.changeValue(e)}
                  value={ndrForm.address || ''}
                  disabled={!customerFeed?.capture?.address}
                />
                <p className="char-counter">
                  {255 - ndrForm.address.length} characters left
                </p>
              </div>
              <div className="mrg-btm-20">
                <span className="label">Pincode</span>
                <input
                  className="input pincode"
                  name="drop_pincode"
                  maxLength="6"
                  onChange={e => this.changeValue(e)}
                  value={ndrForm.drop_pincode || ''}
                  disabled={!customerFeed?.capture?.drop_pincode}
                />
              </div>
              <div className="mrg-btm-20">
                <span className="label">Landmark</span>
                <input
                  className="input landmark"
                  name="landmark"
                  maxLength="128"
                  onChange={e => this.changeValue(e)}
                  value={ndrForm.landmark || ''}
                  disabled={!customerFeed?.capture?.landmark}
                />
              </div>
              <div className="mrg-btm-20">
                <span className="label">Preferred delivery date</span>
                <span
                  className={`${
                    !this.state.preferred_date ? 'custom-calendar' : ''
                  }`}
                >
                  <DatePicker
                    onChange={this.onCalendarChange}
                    minDate={new Date()}
                    selected={this.state.preferred_date}
                    dateFormat="dd-MM-yyyy"
                    isClearable={true}
                    includeDates={this.state.dateMeta.map(
                      item => new Date(item.date)
                    )}
                  />
                </span>
              </div>
            </form>
          </Fragment>
        );
      case 2:
        return (
          <form className="slick-form">
            {this.state.dateMeta.length > 0
              ? this.state.dateMeta.slice(0, 2).map((i, j) => (
                  <div key={j} className="mrg-btm-20">
                    <input
                      id={i.date}
                      data-id={i.date}
                      name="ndr-checkbox"
                      className="ndr-checkbox-days"
                      type="radio"
                      readOnly={true}
                      checked={ndrForm.preferred_date === i.date ? true : false}
                      onChange={e => this.dateToggle(e)}
                    />
                    <label className="pre-text" htmlFor={i.date}>{`${
                      i.dayOfWeek
                    } (${moment(i.date).format('DD-MM-YYYY')})`}</label>
                  </div>
                ))
              : null}
            <div className="mrg-btm-20">
              <span className="label">Any other date?</span>
              <span
                className={`${
                  !this.state.preferred_date ? 'custom-calendar' : ''
                }`}
              >
                <DatePicker
                  onChange={this.onCalendarChange}
                  minDate={new Date()}
                  className="input-text ant-holder"
                  selected={this.state.preferred_date}
                  dateFormat="dd-MM-yyyy"
                  isClearable={true}
                  includeDates={this.state.dateMeta
                    .slice(2)
                    .map(item => new Date(item.date))}
                />
              </span>
            </div>
          </form>
        );
      case 3:
      case 5:
        return (
          <Fragment>
            <form className="slick-form">
              <div className="mrg-btm-20">
                <span className="label">Preferred delivery date</span>
                <span
                  className={`${
                    !this.state.preferred_date ? 'custom-calendar' : ''
                  }`}
                >
                  <DatePicker
                    onChange={this.onCalendarChange}
                    minDate={new Date()}
                    selected={this.state.preferred_date}
                    dateFormat="dd-MM-yyyy"
                    isClearable={true}
                    includeDates={this.state.dateMeta.map(
                      item => new Date(item.date)
                    )}
                  />
                </span>
              </div>
              <div className="mrg-btm-20">
                <textarea
                  name="comment"
                  placeholder="Please enter relevant details"
                  maxLength="500"
                  onChange={e => this.changeValue(e)}
                  value={ndrForm.comment || ''}
                />
                <p className="char-counter">
                  {500 - ndrForm.comment.length} characters left
                </p>
              </div>
            </form>
          </Fragment>
        );
      case 4:
        return (
          <form className="slick-form">
            <div
              id="1"
              className="mrg-btm-15"
              role="button"
              tabIndex="0"
              onClick={this.handleAltNumber}
            >
              <input
                id="1"
                name="ndr-checkbox"
                className="ndr-checkbox"
                type="radio"
                readOnly={true}
                checked={
                  isAltNumber !== null ? (!isAltNumber ? true : false) : false
                }
              />
              <label id="1" className="pre-text" htmlFor="1">
                Yes
              </label>
            </div>

            <div
              id="2"
              className="mrg-btm-20"
              role="button"
              tabIndex="0"
              onClick={this.handleAltNumber}
            >
              <input
                id="2"
                name="ndr-checkbox"
                className="ndr-checkbox"
                type="radio"
                readOnly={true}
                checked={
                  isAltNumber !== null ? (isAltNumber ? true : false) : false
                }
              />
              <label id="2" className="pre-text" htmlFor="2">
                Enter alternate number
              </label>
            </div>
            {isAltNumber ? (
              <div className="mrg-btm-30 mrg-left-30 pos-rel">
                <input
                  className="input phoneNumber"
                  name="phone_number"
                  onChange={e => this.changeValue(e)}
                  value={ndrForm.phone_number || ''}
                  maxLength="10"
                  disabled={!customerFeed?.capture?.phone_number}
                />
                <span className="ph-prefix">+91 </span>
              </div>
            ) : null}

            <div className="mrg-btm-20">
              <span className="label">Preferred delivery date</span>
              <span
                className={`${
                  !this.state.preferred_date ? 'custom-calendar' : ''
                }`}
              >
                <DatePicker
                  onChange={this.onCalendarChange}
                  minDate={new Date()}
                  selected={this.state.preferred_date}
                  dateFormat="dd-MM-yyyy"
                  isClearable={true}
                  includeDates={this.state.dateMeta.map(
                    item => new Date(item.date)
                  )}
                />
              </span>
            </div>
          </form>
        );
      case 6:
        return (
          <form className="slick-form">
            <div
              id="1"
              className="mrg-btm-15"
              role="button"
              tabIndex="0"
              onClick={this.handleReject}
            >
              <input
                id="1"
                name="ndr-checkbox"
                className="ndr-checkbox"
                type="radio"
                readOnly={true}
                checked={
                  ndrForm.is_rejected !== null
                    ? ndrForm.is_rejected
                      ? false
                      : true
                    : false
                }
              />
              <label id="1" className="pre-text" htmlFor="6">
                Yes
              </label>
            </div>

            <div
              id="2"
              className="mrg-btm-25"
              role="button"
              tabIndex="0"
              onClick={this.handleReject}
            >
              <input
                id="2"
                name="ndr-checkbox"
                className="ndr-checkbox"
                type="radio"
                readOnly={true}
                checked={
                  ndrForm.is_rejected !== null
                    ? ndrForm.is_rejected
                      ? true
                      : false
                    : false
                }
              />
              <label id="2" className="pre-text" htmlFor="7">
                No
              </label>
            </div>
            {ndrForm.is_rejected !== null && !ndrForm.is_rejected ? (
              <div className="mrg-btm-20">
                <span className="label">Preferred delivery date</span>
                <span
                  className={`${
                    !this.state.preferred_date ? 'custom-calendar' : ''
                  }`}
                >
                  <DatePicker
                    onChange={this.onCalendarChange}
                    minDate={new Date()}
                    selected={this.state.preferred_date}
                    dateFormat="dd-MM-yyyy"
                    isClearable={true}
                    includeDates={this.state.dateMeta.map(
                      item => new Date(item.date)
                    )}
                  />
                </span>
              </div>
            ) : null}
            <div className="mrg-btm-20">
              <textarea
                placeholder="Please enter relevant details"
                maxLength="500"
                name="comment"
                onChange={e => this.changeValue(e)}
                value={ndrForm.comment || ''}
              />
              <p className="char-counter">
                {500 - ndrForm.comment.length} characters left
              </p>
            </div>
          </form>
        );
      default:
        return null;
    }
  }

  handleNdrSubmit = () => {
    const { currentIssueId, ndrForm, ndrScreen } = this.state;
    const { tracking, page } = this.props;
    const { search, hash } = this.props.location;
    const searchParams = new URLSearchParams(search + hash);
    const security_key =
      searchParams.get('security_key') || searchParams.get('key');
    let query = {};
    if (currentIssueId === 1) {
      query = (({ address, drop_pincode, landmark, preferred_date }) => ({
        address,
        drop_pincode,
        landmark,
        preferred_date
      }))(ndrForm);
    } else if (currentIssueId === 2 || currentIssueId === 3) {
      query = (({ preferred_date }) => ({
        preferred_date
      }))(ndrForm);
      if (currentIssueId === 3) {
        query.comment = ndrForm.comment;
      }
    } else if (currentIssueId === 4) {
      query = (({ phone_number, preferred_date }) => ({
        phone_number,
        preferred_date
      }))(ndrForm);
    } else if (currentIssueId === 6) {
      query = (({ is_rejected, comment }) => ({
        is_rejected,
        comment
      }))(ndrForm);
      if (!query.is_rejected && ndrForm.preferred_date) {
        query.preferred_date = ndrForm.preferred_date;
      }
    }
    // if (query.preferred_date) {
    //   query.preferred_date = moment(query.preferred_date, 'DD-MM-YYYY').format(
    //     'YYYY-MM-DD'
    //   );
    // }
    if (query.preferred_date) {
      query.preferred_date = moment(query.preferred_date).format('YYYY-MM-DD');
    }
    query.issue_id = currentIssueId;
    if (ndrScreen === 1) {
      query.intent = '';
    } else {
      query.intent = clickEventDescription(query.issue_id);
    }
    if (page.source) {
      query.feedback_source = page.source;
    }
    ndrForm.isSubmitting = true;
    query.security_key = security_key;
    this.setState({ ndrForm });
    const toastId = toast.loading(actionMessages.inProgress);
    postConcernHandler(
      tracking.trackingId,
      tracking.config.hasRaisedIssue,
      query
    ).then(res => {
      ndrForm.isSubmitting = false;
      if (res.meta.success) {
        this.setState({ ndrScreen: 4 });
        toast.dismiss(toastId);
      } else {
        toast.error(res.meta.message, { id: toastId });
      }
      this.setState({ ndrForm });
    });
    // form testing
    // const loader = new Promise(resolve => {
    //   resolve(setTimeout(hide, 0));
    // });
    // Promise.all([loader]).then(() => {
    //   ndrForm.isSubmitting = false;
    //   this.setState({ ndrScreen: 4 });
    //   this.setState({ ndrForm });
    // });
  };

  render() {
    const { tracking, user, page, npr } = this.props;
    const { ndrScreen, isReverseIssue } = this.state;
    const enterpriseProfile = tracking.theme.meta_data;
    let hostUrl = '';
    // there is a delay in getting and setting the trackingLink in the tracking object. So, need to check if if is available and is a parseable URL or not first.
    if (URL.canParse(tracking?.trackingLink)) {
      const url = new URL(tracking.trackingLink);
      const urlHostname = url.hostname;
      hostUrl = urlHostname ?? user.userHostname + page.url;
    }
    const scans = mappingDateByStatuses(tracking.scans) || [];
    const settings = {
      infinite: true,
      autoplaySpeed: 3000,
      autoplay: true,
      margin: 0,
      centerMode: true,
      centerPadding: '0px',
      arrows: true,
      slidesToScroll: 1,
      swipeToSlide: false,
      dots: false,
      fade: true,
      speed: 500,
      slidesToShow: 1,
      verticalSwiping: false,
      draggable: false,
      swipe: false
      // beforeChange: (current, next) => {
      //   activeSlide = next;
      // }
      // swipe: false,
      // slidesToScroll: 1
    };
    const { showRating, showReverseRating } = this.props.tracking.config;

    const slides = tracking.banners.map((banner, i) => (
      <div key={i} className="col-md-8 col-xs-24 mrg-top-20 xs-mrg-top-10">
        <div className="slide">
          <a href={banner.redirect_url}>
            <img src={banner.url} alt={banner.alt} />
            {banner.title ? <div className="title">{banner.title}</div> : null}
          </a>
        </div>
      </div>
    ));
    return (
      <Fragment>
        {tracking.isFetching ? (
          <PageSpinner subdomain={user.subdomain} />
        ) : (
          <Fragment>
            <div
              className={`container pd-top-40 xs-pd-top-10 pd-btm-40 xs-pd-btm-10 main-container slick-theme ${user.subdomain}`}
            >
              {this.state.invalidShipment ? (
                <NotFoundCard
                  message={this.state.message}
                  subMessage={this.state.subMessage}
                />
              ) : (
                <>
                  <div className="row">
                    {tracking?.order?.timeline.length > 0 &&
                      tracking.order.timeline[tracking.order.reached].status !==
                        'delivered' && (
                        <Payment wrapperClassName="col-md-16 col-md-offset-4 col-xs-24 mrg-btm-20 xs-mrg-btm-10" />
                      )}
                    {tracking?.order?.timeline.length > 0 &&
                    tracking.order.timeline[tracking.order.reached].status ===
                      'delivered' &&
                    !tracking.config.invalidSecurityCheck &&
                    showRating &&
                    tracking?.latestStatus?.code === 8 ? (
                      <div className="col-md-16 col-md-offset-4 col-xs-24 mrg-btm-20 xs-mrg-btm-10">
                        <RatingCard />
                      </div>
                    ) : null}
                    {tracking?.config?.isRVP &&
                    tracking?.order?.timeline.length > 0 &&
                    reverseNPSStatuses.all.indexOf(tracking?.scans?.[0]?.code) >
                      -1 &&
                    !tracking.config.invalidSecurityCheck &&
                    showReverseRating &&
                    tracking.config.isReverseRating ? (
                      <div className="col-md-16 col-md-offset-4 col-xs-24 mrg-btm-20 xs-mrg-btm-10">
                        <ReverseRating />
                      </div>
                    ) : null}
                    <div className="col-md-8 col-md-offset-4 col-xs-24">
                      <div className="card text-left">
                        <div className="content">
                          {ndrScreen > 0 ? (
                            this.renderNdrView(ndrScreen)
                          ) : isReverseIssue ? (
                            <Issue
                              tracking={tracking}
                              user={user}
                              page={page}
                              history={this.props.history}
                              npr={npr}
                              customerFeed={{
                                ...this.props.customerFeed,
                                ...this.state.customerFeedback
                              }}
                              opsFeed={this.props.opsFeed}
                              security_key={this.state.security_key}
                            />
                          ) : (
                            <Fragment>
                              {tracking.predict.edd &&
                              moment(tracking.predict.edd).diff(
                                new Date(),
                                'days'
                              ) > -1 ? (
                                <Fragment>
                                  <p className="mrg-btm-5 expected-date-label">
                                    Expected delivery by
                                  </p>
                                  <p className="text-theme expected-date mrg-btm-15">
                                    {datetimeFormatter(
                                      tracking.predict.edd,
                                      true
                                    )}
                                  </p>
                                </Fragment>
                              ) : null}
                              {orderData.returned.indexOf(
                                tracking.latestStatus.code
                              ) > -1 ? (
                                <p className="text-theme expected-date mrg-btm-10 delayed">
                                  <strong>Shipment Returned</strong>
                                </p>
                              ) : null}
                              <div className="latest-update col-gutter-20 xs-col-gutter-10">
                                <p className="status-text mrg-btm-5">
                                  {tracking.latestStatus.text}
                                </p>
                                {tracking.predict.message ? (
                                  <p style={{ color: tracking.predict.color }}>
                                    {tracking.predict.message}
                                  </p>
                                ) : null}
                                <p className="expected-date-label">
                                  Last update:{' '}
                                  {datetimeFormatter(
                                    tracking.latestStatus.timestamp,
                                    true,
                                    true
                                  )}
                                </p>
                              </div>
                            </Fragment>
                          )}

                          <div className="timeline-statuses">
                            <div
                              className={`timeline scrollbar ${
                                tracking.scans.length < 2
                                  ? 'overflow-y-hidden'
                                  : ''
                              }`}
                            >
                              {scans.length > 0 ? (
                                scans.map((item, i) => (
                                  <div
                                    key={i}
                                    className={`date-list ${
                                      i === 0 ? 'active' : ''
                                    } ${scans.length <= 1 ? 'one-item' : ''}`}
                                  >
                                    <h3>
                                      <strong>
                                        {datetimeFormatter(item.date)}
                                      </strong>
                                    </h3>
                                    <ul className="date">
                                      {item.list.map((scan, i) => (
                                        <li key={i}>
                                          <span className="time">
                                            {moment(scan.timestamp).format(
                                              'LT'
                                            )}
                                          </span>
                                          <span className="remark">
                                            {scan.remark}
                                          </span>
                                          <span className="location">
                                            {scan.location}
                                          </span>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                ))
                              ) : tracking?.order?.timeline[
                                  tracking?.order?.reached
                                ]?.status === 'delivered' ? (
                                <p className="mrg-top-10 mrg-btm-10 text-left">
                                  Carrier scans are not currently available.
                                </p>
                              ) : (
                                <p className="mrg-top-10 mrg-btm-10 text-left">
                                  Ready to Ship!
                                  <br />
                                  {`Tracking details will be available once the package arrives at the ${tracking.courier.name} facility. Please check back soon!`}
                                </p>
                              )}
                              {/* </ul> */}
                            </div>
                          </div>
                          <hr className="mrg-top-10 mrg-btm-10 visible-xs" />
                          <div className="shipment-detail visible-xs">
                            <h3>Shipment Detail</h3>
                            {tracking.orderId ? (
                              <div className="row">
                                <div className="col-md-10 col-xs-9 expected-date-label mrg-btm-10 pd-right-0">
                                  Order ID
                                </div>
                                <div className="col-md-14 col-xs-15 mrg-btm-10">
                                  {tracking.orderId}
                                </div>
                              </div>
                            ) : null}
                            {tracking.config.orderDate ? (
                              <div className="row">
                                <div className="col-md-10 col-xs-9 expected-date-label mrg-btm-10 pd-right-0">
                                  Order Placed On
                                </div>
                                <div className="col-md-14 col-xs-15 mrg-btm-10">
                                  {tracking.config.orderDate &&
                                    moment(tracking.config.orderDate).format(
                                      'dddd, Do MMM YYYY'
                                    )}
                                </div>
                              </div>
                            ) : null}
                            <div className="row">
                              <div className="col-md-10 col-xs-9 expected-date-label mrg-btm-10 pd-right-0">
                                Delivery Partner
                              </div>
                              <div className="col-md-14 col-xs-15 mrg-btm-10">
                                {tracking.courier.name}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-10 col-xs-9 expected-date-label mrg-btm-10 pd-right-0">
                                AWB Number
                              </div>
                              <div className="col-md-14 col-xs-15 mrg-btm-10">
                                {tracking.waybill}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8 col-xs-24 hidden-xs">
                      <div className="card text-left">
                        <div className="content">
                          <div className="shipment-detail">
                            <h3>Shipment Detail</h3>
                            {tracking.orderId ? (
                              <div className="row">
                                <div className="col-md-10 col-xs-9 expected-date-label mrg-btm-10 pd-right-0">
                                  Order ID
                                </div>
                                <div className="col-md-14 col-xs-15 mrg-btm-10">
                                  {tracking.orderId}
                                </div>
                              </div>
                            ) : null}
                            {tracking.config.orderDate ? (
                              <div className="row">
                                <div className="col-md-10 col-xs-9 expected-date-label mrg-btm-10 pd-right-0">
                                  Order Placed On
                                </div>
                                <div className="col-md-14 col-xs-15 mrg-btm-10">
                                  {tracking.config.orderDate &&
                                    moment(tracking.config.orderDate).format(
                                      'dddd, Do MMM YYYY'
                                    )}
                                </div>
                              </div>
                            ) : null}
                            <div className="row">
                              <div className="col-md-10 col-xs-9 expected-date-label mrg-btm-10 pd-right-0">
                                Delivery Partner
                              </div>
                              <div className="col-md-14 col-xs-15 mrg-btm-10">
                                {tracking.courier.name}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-10 col-xs-9 expected-date-label mrg-btm-10 pd-right-0">
                                AWB Number
                              </div>
                              <div className="col-md-14 col-xs-15 mrg-btm-10">
                                {tracking.waybill}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row hide">{slides}</div>
                  <div className="row hide">
                    <Slider {...settings}>{slides}</Slider>
                  </div>
                </>
              )}
            </div>
          </Fragment>
        )}
        <AppPopUp user={user} tracking={tracking} page={page} />
        {tracking.isFetching ? null : (
          <div
            className={`container-fluid black-overlay visible-xs ${
              user.shareOn ? 'visible-xs' : 'hidden-xs'
            }`}
            onClick={this.closeShare}
          >
            <div className="row">
              <div
                className="col-xs-24 share-container"
                onClick={e => e.stopPropagation()}
              >
                <span
                  className="icon-close cursor-pointer"
                  onClick={this.closeShare}
                />
                <p className="header">Share with friends</p>
                <ul>
                  <li
                    onClick={e =>
                      this.gaEvents(e, 'Social', 'Whatsapp', 'product share')
                    }
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`whatsapp://send?text=Track my order @%20${hostUrl}?ref=social_wa_clickpost`}
                    >
                      <span className="active icon-whatsapp" /> WhatsApp
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={e =>
                        this.gaEvents(e, 'Social', 'Facebook', 'product share')
                      }
                      href={`https://www.facebook.com/dialog/share?app_id=1932988240339501&display=popup&href=${hostUrl}&redirect_uri=${encodeURIComponent(
                        hostUrl
                      )}&hashtag=${
                        enterpriseProfile.hashtag
                      }&quote=Track my order`}
                    >
                      <span className="active icon-fb" /> Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={e =>
                        this.gaEvents(e, 'Social', 'Twitter', 'product share')
                      }
                      href={`https://twitter.com/intent/tweet?original_referer=${tracking.config.website}?ref=social_tw_clickpost&text=I just bought ${tracking.product.title} from ${enterpriseProfile.title}&url=${tracking.config.website}?ref=social_tw_clickpost&via=${enterpriseProfile.twitterHandle}`}
                    >
                      <span className="active icon-twitter" /> Twitter
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

Tracking.contextTypes = {
  router: PropTypes.object.isRequired
};

Tracking.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  rating: PropTypes.object,
  page: PropTypes.object,
  updateUserHandler: PropTypes.func,
  location: PropTypes.object,
  setPageHandler: PropTypes.func,
  fetchTrackingHandler: PropTypes.func,
  fetchEddHandler: PropTypes.func,
  fetchRatingHandler: PropTypes.func,
  fetchOpsFeedHandler: PropTypes.func,
  fetchCustomerFeedHandler: PropTypes.func,
  history: PropTypes.object,
  customerFeed: PropTypes.object,
  opsFeed: PropTypes.object,
  fetchNprOpsFeedHandler: PropTypes.func,
  fetchNprCustomerFeedHandler: PropTypes.func,
  fetchReverseRatingHandler: PropTypes.func,
  npr: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user,
  rating: state.rating,
  page: state.page,
  customerFeed: state.customerFeed,
  opsFeed: state.opsFeed,
  npr: state.npr
});

const mapDispatchToProps = dispatch => ({
  updateUserHandler: user => dispatch(updateUserHandler(user)),
  setPageHandler: query => dispatch(pageHandler(query)),
  fetchTrackingHandler: query => dispatch(trackingHandler(query)),
  fetchEddHandler: query => dispatch(eddHandler(query)),
  fetchRatingHandler: trackingId => dispatch(ratingHandler(trackingId)),
  fetchOpsFeedHandler: (trackingId, user) =>
    dispatch(opsFeedHandler(trackingId, user)),
  fetchCustomerFeedHandler: (tracking, customerInfo, security_key) =>
    dispatch(customerFeedHandler(tracking, customerInfo, security_key)),
  fetchNprOpsFeedHandler: (trackingId, security_key) =>
    dispatch(nprOpsFeedHandler(trackingId, security_key)),
  fetchNprCustomerFeedHandler: (tracking, customerInfo) =>
    dispatch(nprCustomerFeedHandler(tracking, customerInfo)),
  fetchReverseRatingHandler: trackingId =>
    dispatch(reverseRatingHandler(trackingId))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Tracking)
);
